import { createApp } from 'vue'

import * as Sentry from '@sentry/vue'
import Toast, { useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import Vue3TouchEvents from 'vue3-touch-events'
import { directive as onClickaway } from 'vue3-click-away'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import {
  faBell,
  faCircle,
  faInfoCircle,
  faCog,
  faBan,
  faCheck,
  faCheckCircle,
  faCommentAlt,
  faExclamationTriangle,
  faExclamationCircle,
  faCreditCard,
  faCalendarPlus,
  faEnvelope,
  faFileExcel,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPercentage,
  faClock,
  faCoins,
  faTimes,
  faPlus,
  faTicketAlt,
  faPrint,
  faChevronLeft,
  faTimesCircle,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons'
import { faViber } from '@fortawesome/free-brands-svg-icons'

import Hypher from 'hypher'
import russian from 'hyphenation.ru'

import App from './App'
import router from './router'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBackButton from '@/components/common/BaseBackButton.vue'
import BaseCalendar from '@/components/common/BaseCalendar.vue'
import BaseCloseButton from '@/components/common/BaseCloseButton.vue'
import BaseDatepicker from '@/components/common/BaseDatepicker.vue'
import BaseDateRangeSelector from '@/components/common/BaseDateRangeSelector.vue'
import BaseDropdown from '@/components/common/BaseDropdown.vue'
import BaseFormError from '@/components/common/BaseFormError.vue'
import BaseMonth from '@/components/common/BaseMonth.vue'
import BaseMonthSelector from '@/components/common/BaseMonthSelector.vue'
import BaseNavigation from '@/components/common/BaseNavigation.vue'
import BaseSpinner from '@/components/common/BaseSpinner.vue'

import './static/styles/animations.css'
import './static/styles/button.css'
import './static/styles/dialog.css'
import './static/styles/forms.css'
import './static/styles/global.css'
import './static/styles/table.css'
import './static/styles/tabs.css'
import './static/styles/toast.css'
import './static/styles/variables.css'

import { pluralize } from '@/utils'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceWorker.js')
}

const hypher = new Hypher(russian)

const app = createApp(App)

// plugins

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN
  })
}

app.use(router)

app.use(Toast, {
  position: 'top-center',
  timeout: 5000,
  transition: 'Vue-Toastification__fade',
  toastClassName: 'toast-custom',
  bodyClassName: 'toast-body-custom',
  showCloseButtonOnHover: true
})

app.use(Vue3TouchEvents)

// directives

app.directive('on-clickaway', onClickaway)

app.directive('focus', {
  mounted (el, { value }) {
    if (value === undefined || !!value) {
      el.focus()
    }
  }
})

// components

app.component('BaseAlert', BaseAlert)
app.component('BaseBackButton', BaseBackButton)
app.component('BaseCalendar', BaseCalendar)
app.component('BaseCloseButton', BaseCloseButton)
app.component('BaseDatepicker', BaseDatepicker)
app.component('BaseDateRangeSelector', BaseDateRangeSelector)
app.component('BaseDropdown', BaseDropdown)
app.component('BaseFormError', BaseFormError)
app.component('BaseMonth', BaseMonth)
app.component('BaseMonthSelector', BaseMonthSelector)
app.component('BaseNavigation', BaseNavigation)
app.component('BaseSpinner', BaseSpinner)

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('FontAwesomeLayers', FontAwesomeLayers)

library.add(
  faBell,
  faCircle,
  faInfoCircle,
  faCog,
  faBan,
  faCheck,
  faCheckCircle,
  faCommentAlt,
  faExclamationTriangle,
  faExclamationCircle,
  faCreditCard,
  faCalendarPlus,
  faEnvelope,
  faFileExcel,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPercentage,
  faClock,
  faCoins,
  faTimes,
  faPlus,
  faViber,
  faTicketAlt,
  faPrint,
  faChevronLeft,
  faTimesCircle,
  faFilePdf
)

// global properties

app.config.globalProperties.$toast = useToast()

app.config.globalProperties.$utils = {}

app.config.globalProperties.$utils.formatDecimal = value => {
  const number = Number(value)
  const truncated = Math.trunc(number)

  if (truncated === number) {
    return truncated.toLocaleString()
  }

  return number.toLocaleString()
}

app.config.globalProperties.$utils.formatDate = (value, extra) => {
  if (!value) return ''

  const date = new Date(value)

  return date.toLocaleDateString(
    'ru-RU',
    {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
      ...extra
    }
  )
}

app.config.globalProperties.$utils.formatDateTime = (value, extra) => {
  if (!value) return ''

  const date = new Date(value)

  return date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
    ...extra
  })
}

app.config.globalProperties.$utils.hyphenate = text => hypher.hyphenateText(text)

app.config.globalProperties.$utils.unbreak =
  text =>
    text
      .split(/\s+/)
      .map(word =>
        word.length <= 3
          ? word + '\u00a0'
          : word + ' '
      )
      .join('')

app.config.globalProperties.$utils.pluralize = pluralize

app.config.globalProperties.$utils.formatPhoneNumber = phone => {
  let i = 0
  return '## (###) ###-##-##'.replace(/#/g, () => phone.charAt(i++))
}

app.config.globalProperties.$utils.trimPlus = phone => phone.replace(/^\+/, '')

app.config.globalProperties.$utils.truncate =
  (string, truncateAt) =>
    string.length > truncateAt
      ? `${string.substring(0, truncateAt)}...`
      : string

// config

app.config.unwrapInjectedRef = true

// mount

app.mount('#app')

<script setup>
import { defineProps, computed, ref } from 'vue'
import { useToast } from 'vue-toastification'

import api from '@/api'
import { getErrors } from '@/errors'

import AttendanceLegend from '@/components/journal/parent/child/AttendanceLegend.vue'
import AttendanceMarkStyles from '@/components/journal/parent/child/AttendanceMark.module.css'

const toast = useToast()

const props = defineProps(['childID', 'monthStart'])

const attendances = ref([])
const isPending = ref(false)

const fetchAttendances = ({ rangeFrom, rangeTo }) => {
  attendances.value = []
  isPending.value = true

  api.get('/journal/attendances/', {
    params: {
      child: props.childID,
      date__gte: rangeFrom,
      date__lte: rangeTo
    }
  })
    .then(response => {
      attendances.value = response.data
    }, error => {
      toast.error(getErrors(error).detail)
    })
    .then(() => {
      isPending.value = false
    })
}

const attendancesByDate = computed(() => {
  const result = {}

  for (const attendance of attendances.value) {
    result[attendance.date] = {
      tooltip: attendance.receipt && (
        attendance.receipt.subscription
          ? `По абонементу "${attendance.receipt.subscription.name}"`
          : `Стоимость занятия: ${attendance.receipt.amount}`
      ),
      class: [
        AttendanceMarkStyles.attendance,
        AttendanceMarkStyles.pending,
        attendance.receipt && AttendanceMarkStyles.confirmed,
        attendance.is_unpaid && AttendanceMarkStyles.unpaid
      ]
    }
  }

  return result
})
</script>

<template>
  <div>
    <BaseSpinner v-if="isPending" :withBackground="true"/>
    <BaseMonth
      :monthStart="monthStart"
      :marks="attendancesByDate"
      @changeRange="fetchAttendances"
    />
    <AttendanceLegend confirmed pending unpaid />
  </div>
</template>

<style scoped>

</style>

<script setup>
import axios from 'axios'
import { defineProps, computed, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'

import api from '@/api'
import { getErrors } from '@/errors'
import { addDays, addMonths, dateToISO, keyToCmp } from '@/utils'

import AttendanceLegend from '@/components/journal/parent/child/AttendanceLegend.vue'
import AttendanceMark from '@/components/journal/parent/child/AttendanceMark.vue'

const toast = useToast()

const props = defineProps(['childID', 'groupID', 'monthStart'])

const attendances = ref([])
const themes = ref([])
const isPending = ref(false)

const range = computed(() => ({
  rangeFrom: dateToISO(props.monthStart),
  rangeTo: dateToISO(addDays(addMonths(props.monthStart, 1), -1))
}))

const groupByDate = (mapping, getDate = (item) => item.date) => {
  const combined = []
  const groupsByDate = {}

  for (const [key, items] of Object.entries(mapping)) {
    for (const item of items) {
      const date = getDate(item)

      let group = groupsByDate[date]
      if (!group) {
        group = {}
        groupsByDate[date] = group
        combined.push([date, group])
      }

      group[key] = item
    }
  }

  combined.sort(keyToCmp(([date]) => date))

  return combined
}

const marks = computed(() => groupByDate({
  attendance: attendances.value,
  theme: themes.value
}))

const fetchMarks = ({ rangeFrom, rangeTo }) => {
  isPending.value = true

  axios.all([
    api.get('/journal/attendances/', {
      params: {
        child: props.childID,
        date__gte: rangeFrom,
        date__lte: rangeTo
      }
    }),
    api.get('/journal/themes/', {
      params: {
        group: props.groupID,
        date__gte: rangeFrom,
        date__lte: rangeTo
      }
    })
  ])
    .then(([_attendances, _themes]) => {
      attendances.value = _attendances.data
      themes.value = _themes.data
    }, error => {
      toast.error(getErrors(error).detail)
    })
    .then(() => {
      isPending.value = false
    })
}

watch(range, fetchMarks, { immediate: true })
</script>

<template>
  <div class="attendances-list-wrapper">
    <BaseSpinner v-if="isPending" :withBackground="true"/>
    <div class="attendances-list">
      <div
        v-if="marks.length === 0"
        class="no-marks"
      >
        Нет занятий в этом месяце
      </div>
      <div
        v-for="[date, { attendance, theme }] in marks"
        :key="date"
        class="attendance-row"
      >
        <div class="attendance-header">
          <AttendanceMark
            :pending="!!attendance"
            :confirmed="!!attendance && !!attendance.receipt"
            :unpaid="!!attendance && !!attendance.is_unpaid"
          />
          {{ $utils.formatDate(date, {weekday: 'short', year: undefined}) }}
        </div>
        <div v-if="theme" class="attendance-theme">
          {{ theme.text }}
        </div>
      </div>
    </div>
    <AttendanceLegend confirmed pending unpaid absent />
  </div>
</template>

<style scoped>
.attendances-list-wrapper {
  margin-top: 1.5em;
}

.attendances-list {
  height: calc(358px + 0.3em);
  overflow: auto;
}

.attendance-row {
  margin-bottom: 1em;
}

.attendance-header {
  line-height: 1.7em;
  font-weight: 600;
}

.attendance-theme {
  margin: 0.3em 0;
  padding-left: 1.55em;
  white-space: pre-line;
}

.no-marks {
  font-style: italic;
  text-align: center;
}
</style>

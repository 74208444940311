<template>
  <div
    class="theme-wrapper"
    @click="handleClick"
    :title="tooltip"
  >
    <div
      :class="[
        'theme',
        {'marked': isMarked},
        {'pending': isPending},
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ThemeMark',

  props: [
    'date',
    'group',
    'theme'
  ],

  data () {
    return {
      isPending: false
    }
  },

  computed: {
    isMarked () {
      return !!this.theme
    },

    tooltip () {
      return this.theme && `Отметил: ${this.theme.created_by}`
    }
  },

  methods: {
    handleClick () {
      const theme = this.theme || {
        date: this.date,
        group: this.group.id,
        text: ''
      }

      this.$emit('edit', theme)
    }
  }
}
</script>

<style scoped>
.theme-wrapper {
  width: 2em;
  height: 2em;

  padding: 0.4em;

  cursor: pointer;

  position: relative;
}

.theme {
  width: 100%;
  height: 100%;

  border-radius: 40%;

  background-color: var(--light-overlay);
}

.theme.pending {
  background-color: var(--light-gray);
  filter: brightness(0.7);
}

.theme.marked {
  background-color: var(--purple);
}
</style>

<template>
  <div class="calendar">
    <BaseMonthSelector
      v-model:date="monthStart"
      :showYearSelector="showYearSelector"
    >
      <BaseMonth
        :monthStart="monthStart"
        :marks="marks"
        :isSelectable="isSelectable"
        :activeStart="activeStart"
        :activeEnd="activeEnd"
        @changeRange="range => $emit('changeRange', range)"
        @select="date => $emit('select', date)"
      />
    </BaseMonthSelector>
  </div>
</template>

<script>
export default {
  name: 'BaseCalendar',

  props: [
    'marks',
    'isSelectable',
    'showYearSelector',
    'activeStart',
    'activeEnd'
  ],

  emits: ['changeRange', 'select'],

  data () {
    const now = new Date()

    return {
      monthStart: new Date(
        now.getFullYear(),
        now.getMonth(),
        1
      )
    }
  }
}
</script>

<style scoped>

</style>

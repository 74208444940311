<script>
import { addMonths, addYears } from '@/utils'

const monthNames = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август',
  'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
]

export default {
  name: 'BaseMonthSelector',

  props: [
    'date',
    'showYearSelector'
  ],

  computed: {
    year () {
      return this.date.getFullYear()
    },

    monthName () {
      return monthNames[this.date.getMonth()]
    }
  },

  methods: {
    decrementYear () {
      this.$emit('update:date', addYears(this.date, -1))
    },

    incrementYear () {
      this.$emit('update:date', addYears(this.date, +1))
    },

    decrementMonth () {
      this.$emit('update:date', addMonths(this.date, -1))
    },

    incrementMonth () {
      this.$emit('update:date', addMonths(this.date, +1))
    }
  }
}
</script>

<template>
  <div class="month-wrapper">
    <div v-if="showYearSelector" class="month-selector">
      <div @click="decrementYear" class="month-button">
        <font-awesome-icon icon="angle-left" />
      </div>
      <div class="month-name">{{ year }}</div>
      <div @click="incrementYear" class="month-button">
        <font-awesome-icon icon="angle-right" />
      </div>
    </div>
    <div class="month-selector">
      <div @click="decrementMonth" class="month-button">
        <font-awesome-icon icon="angle-left" />
      </div>
      <div class="month-name">
        {{ monthName }}
        {{ showYearSelector ? null : year }}
      </div>
      <div @click="incrementMonth" class="month-button">
        <font-awesome-icon icon="angle-right" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<style scoped>
.month-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.month-name {
  width: 9em;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  user-select: none;
}

.month-button {
  padding: 0 0.25em;
  font-size: 3em;
  cursor: pointer;

  color: var(--green);
  opacity: 0.4;
}
</style>

<script setup>
import { defineEmits, defineProps, computed, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'

import api from '@/api'
import eventBus from '@/eventBus'
import { getErrors } from '@/errors'

const API = {
  create: (data) => api.post('/journal/themes/', data),
  update: (id, data) => api.patch(`/journal/themes/${id}/`, data),
  destroy: (id) => api.delete(`/journal/themes/${id}/`)
}

const toast = useToast()

const props = defineProps(['theme'])
const emit = defineEmits(['close'])

const text = ref('')

const isSubmitting = ref(false)
const isDeleting = ref(false)

const isPending = computed(() => isSubmitting.value || isDeleting.value)

const close = () => {
  emit('close')
}

const submit = () => {
  if (isSubmitting.value) return

  isSubmitting.value = true

  const promise = props.theme.id
    ? API.update(props.theme.id, { text: text.value })
    : API.create({ ...props.theme, text: text.value })

  promise
    .then((response) => {
      toast.success('Тема урока сохранена')
      eventBus.emit('updateTheme')
      close()
    }, (error) => {
      const errors = getErrors(error)
      toast.error(
        errors.detail ||
        (errors.non_field_errors && errors.non_field_errors.join(' '))
      )
    })
    .then(() => {
      isSubmitting.value = false
    })
}

const deleteMark = () => {
  if (isDeleting.value) return
  if (!props.theme.id) return

  isDeleting.value = true

  API.destroy(props.theme.id)
    .then((response) => {
      toast.success('Тема урока удалена')
      eventBus.emit('updateTheme')
      close()
    }, (error) => {
      toast.error(getErrors(error).detail)
    })
    .then(() => {
      isDeleting.value = false
    })
}

watch(() => props.theme, (theme) => {
  text.value = theme.text
}, { immediate: true })
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <form @submit.prevent="submit">
      <div class="dialog-box">
        <div class="dialog-header">
          <div class="dialog-header-text">
            Тема урока
          </div>
          <BaseCloseButton @click="close" />
        </div>
        <div class="dialog-content">
          <div class="date-wrapper">
            <div class="date">
              {{ $utils.formatDate(props.theme.date) }}
            </div>
            <a
              v-if="props.theme.id"
              class="delete-button"
              @click="deleteMark"
            >
              <span class="delete-button-text">Удалить</span>
            </a>
          </div>
          <textarea
            class="text-area"
            v-model="text"
            v-focus
            :disabled="isPending"
            spellcheck
          ></textarea>
        </div>
        <div class="dialog-buttons">
          <button
            class="button no-margin"
            :disabled="isPending || !text.trim()"
          >Сохранить</button>
          <button type="button" class="button no-background no-margin" @click="close">Отмена</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.dialog-box {
  width: 30em;
}

.date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date {
  padding: 0.1em 0.5em;
  border-left: 0.2em solid var(--purple);
  font-weight: bold;
}

.delete-button {
  color: var(--red);
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.delete-button-text {
  text-decoration: solid underline;
}

.text-area {
  width: 100%;
  height: 10em;

  margin-top: 1.5em;
  padding: 0.7em;

  border: 1px solid var(--medium-gray);
  border-radius: 0.5em;

  font-family: inherit;
  font-size: inherit;

  resize: none;
}
</style>
